<template>
    <span class="downtime" v-if="timeDifference">-{{ days }}d {{ hours }}h {{ minutes }}m</span>
    <span class="snippet" v-else data-title="dot-pulse">
        <div class="stage">
            <div class="dot-pulse"></div>
        </div>
    </span>
</template>

<script>
export default {
    name: "countDownTimer",
    props: ['value'],
    data() {
        return {
            accessEndDate: null,
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            timeDifference: 0,
            timerInterval: null,
        };
    },
    mounted() {
        this.calculateEndDate();
        this.startTimer();
    },
    methods: {
        calculateEndDate() {
            this.accessEndDate = new Date(new Date(this.value).getTime() + 30 * 24 * 60 * 60 * 1000);
        },
        updateTimer() {
            const currentDate = new Date();
            this.timeDifference = this.accessEndDate.getTime() - currentDate.getTime();
            this.days = Math.floor(this.timeDifference / (1000 * 60 * 60 * 24));
            this.hours = Math.floor((this.timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            this.minutes = Math.floor((this.timeDifference % (1000 * 60 * 60)) / (1000 * 60));
            this.seconds = Math.floor((this.timeDifference % (1000 * 60)) / 1000);

            if (this.timeDifference <= 0) {
                clearInterval(this.timerInterval);
            }
        },
        startTimer() {
            this.timerInterval = setInterval(this.updateTimer, 1000);
        },
    },
}
</script>

<style scoped>
.dot-pulse {
    position: relative;
    left: -9982px;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: #20C4F5;
    color: #20C4F5;
    box-shadow: 9982px 0 0 -5px;
    animation: dot-pulse 1.5s infinite linear;
    animation-delay: 0.25s;
}
.dot-pulse::before, .dot-pulse::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: #20C4F5;
    color: #20C4F5;
}
.dot-pulse::before {
    box-shadow: 9984px 0 0 -5px;
    animation: dot-pulse-before 1.5s infinite linear;
    animation-delay: 0s;
}
.dot-pulse::after {
    box-shadow: 10014px 0 0 -5px;
    animation: dot-pulse-after 1.5s infinite linear;
    animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
    0% {
        box-shadow: 9984px 0 0 -5px;
    }
    30% {
        box-shadow: 9984px 0 0 2px;
    }
    60%, 100% {
        box-shadow: 9984px 0 0 -5px;
    }
}
@keyframes dot-pulse {
    0% {
        box-shadow: 9999px 0 0 -5px;
    }
    30% {
        box-shadow: 9999px 0 0 2px;
    }
    60%, 100% {
        box-shadow: 9999px 0 0 -5px;
    }
}
@keyframes dot-pulse-after {
    0% {
        box-shadow: 10014px 0 0 -5px;
    }
    30% {
        box-shadow: 10014px 0 0 2px;
    }
    60%, 100% {
        box-shadow: 10014px 0 0 -5px;
    }
}
</style>
