<template>
    <div class="grid_table manage_uploaded_releases">
        <div class="item_row heading">
            <div class="cover_release"></div>
            <div class="name">
                <span>Title</span>
                <div class="sort">
                    <div class="asc" @click="setOrderBy('title', 'asc')" :class="{active: orderBy.sort === 'title' && orderBy.order === 'asc'}"></div>
                    <div class="desc" @click="setOrderBy('title', 'desc')" :class="{active: orderBy.sort === 'title' && orderBy.order === 'desc'}"></div>
                </div>
            </div>
            <div class="artist">
                <span>Artist</span>
                <div class="sort">
                    <div class="asc" @click="setOrderBy('artist.name', 'asc')"
                         :class="{active: orderBy.sort === 'artist.name' && orderBy.order === 'asc'}"></div>
                    <div class="desc" @click="setOrderBy('artist.name', 'desc')"
                         :class="{active: orderBy.sort === 'artist.name' && orderBy.order === 'desc'}"></div>
                </div>
            </div>
<!--            <div class="released">-->
<!--                <span>Released</span>-->
<!--                <div class="sort">-->
<!--                    <div class="asc" @click="setOrderBy('releasedAt', 'asc')" :class="{active: orderBy.sort === 'releasedAt' && orderBy.order === 'asc'}"></div>-->
<!--                    <div class="desc" @click="setOrderBy('releasedAt', 'desc')"-->
<!--                         :class="{active: orderBy.sort === 'releasedAt' && orderBy.order === 'desc'}"></div>-->
<!--                </div>-->
<!--            </div>-->
	        <div class="status">
		        <span>Status</span>
		        <div class="sort">
			        <div class="asc" @click="setOrderBy('status', 'asc')" :class="{active: orderBy.sort === 'status' && orderBy.order === 'asc'}"></div>
			        <div class="desc" @click="setOrderBy('status', 'desc')"
			             :class="{active: orderBy.sort === 'status' && orderBy.order === 'desc'}"></div>
		        </div>
	        </div>

            <div class="rank align_right">
                <span>Rank</span>
                <div class="sort">
                    <div class="asc" @click="setOrderBy('rank', 'asc')" :class="{active: orderBy.sort === 'rank' && orderBy.order === 'asc'}"></div>
                    <div class="desc" @click="setOrderBy('rank', 'desc')" :class="{active: orderBy.sort === 'rank' && orderBy.order === 'desc'}"></div>
                </div>
            </div>
            <div class="downloads align_right">
                <span>Downloads</span>
                <div class="sort">
                    <div class="asc" @click="setOrderBy('downloads', 'asc')" :class="{active: orderBy.sort === 'downloads' && orderBy.order === 'asc'}"></div>
                    <div class="desc" @click="setOrderBy('downloads', 'desc')"
                         :class="{active: orderBy.sort === 'downloads' && orderBy.order === 'desc'}"></div>
                </div>
            </div>
            <div class="likes align_right">
                <span>Likes</span>
                <div class="sort">
                    <div class="asc" @click="setOrderBy('likes', 'asc')" :class="{active: orderBy.sort === 'likes' && orderBy.order === 'asc'}"></div>
                    <div class="desc" @click="setOrderBy('likes', 'desc')" :class="{active: orderBy.sort === 'likes' && orderBy.order === 'desc'}"></div>
                </div>
            </div>
            <div class="revenu align_right">
                <span>Revenue</span>
                <div class="sort">
                    <div class="asc" @click="setOrderBy('revenu', 'asc')" :class="{active: orderBy.sort === 'revenu' && orderBy.order === 'asc'}"></div>
                    <div class="desc" @click="setOrderBy('revenu', 'desc')" :class="{active: orderBy.sort === 'revenu' && orderBy.order === 'desc'}"></div>
                </div>
            </div>
            <div class="price align_right">
                <span>Price</span>
                <div class="sort">
                    <div class="asc" @click="setOrderBy('price', 'asc')" :class="{active: orderBy.sort === 'price' && orderBy.order === 'asc'}"></div>
                    <div class="desc" @click="setOrderBy('price', 'desc')" :class="{active: orderBy.sort === 'price' && orderBy.order === 'desc'}"></div>
                </div>
            </div>
            <div class="more_actions"></div>
        </div><!-- end item_row heading -->

        <template v-if="dataTable && dataTable.length">
            <div class="item_row data" v-for="item of dataTable" :key="item.id">
                <div class="cover_release">
                    <img :src="item.smallImage" alt="">
                </div>
                <div class="name">
                    <span class="album_name" v-if="item.status == 'draft'" @click="redirectToEditAlbum(item.id, item.identifier)">{{ item.title }}</span>
                    <span class="album_name" v-else @click="redirectToAlbum(item.id, item.identifier)">{{ item.title }}</span>
                </div>
                <div class="artist">
                    <div v-if="item.artist" @click="redirectToArtistPage(item.artist.id, item.artist.name)"> <!-- identifier -->
                        {{ item.artist.name }}
                    </div>
                </div>
<!--                <div class="released">{{ item.releasedAt | moment("MMM YYYY") }}</div>-->
	            <div class="status" style="display: inline-block;">
                    {{item.status}}
                    <countDownTimer v-if="item.status == 'draft' && item.draftAt" v-model="item.draftAt"></countDownTimer>
                </div>
                <div class="rank align_right">-</div>
                <div class="downloads align_right">-</div>
                <div class="likes align_right">-</div>
                <div class="revenu align_right">-</div>
                <div class="price align_right">
	                <template v-if="item.price > 0">${{item.price}}</template>
	                <template v-if="item.price === 0">SYP</template>
	                <template v-if="item.price === null">-</template>
                </div>
                <div class="more">
                    <musicReleaseActions :albumID="item.id"
                                         :albumStatus="!item.status ? null : item.status"
                                         :isOwner="(!item.owner && !item.isOwner) ? null : (item.isOwner || item.owner.id === userID)">
                    </musicReleaseActions>
                </div>
            </div>

            <pagination v-if="countReleases > 30"
                :count="countReleases"
                :currentPage="currentPage"
                :commit="'manageUploadsReleases/SET_CURRENT_PAGE'"
            ></pagination>
        </template>

        <template v-if="loader">
            <v-skeleton-loader
                type="image"
                class="table_item_row"
                v-for="index in 10"
                :key="index"
            ></v-skeleton-loader>
        </template>

        <template v-if="!loader && !dataTable.length">
            <nothingHereYet></nothingHereYet>
        </template>

    </div>
</template>

<script>
import {mapGetters} from "vuex";

import musicReleaseActions from "@/components/musicReleases/albumActions";
import pagination from "@/components/pagination";
import nothingHereYet from "@/components/small/nothingHereYet";
import countDownTimer from "@/components/small/countDownTimer.vue"

export default {
    name: "uploadedReleasesTable",
    props: ['dataTable'],
    components: {
        musicReleaseActions,
        pagination,
        nothingHereYet,
        countDownTimer
    },
    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL,
            isOwner: false,
        }
    },
    computed: {
        ...mapGetters({
            userID: 'userID',
            loader: 'manageUploadsReleases/RELEASES_LOADER',
            orderBy: 'manageUploadsReleases/ORDER_BY',
            currentPage: 'manageUploadsReleases/CURRENT_PAGE',
            countReleases: 'manageUploadsReleases/COUNT_RELEASES'
        }),
    },
    created() {
		console.log('dataTable', this.dataTable);
	    // console.log('this.musicRelease', this.musicRelease);
        // if (this.musicRelease.owner) {
        //     // let ownerID = this.musicRelease.owner.id;
        //     // if (+this.userID === +ownerID) {
        //     //     this.isOwner = true;
        //     // }
        // }
    },
    mounted() {

    },
    methods: {
	    redirectToAlbum(albumID, albumName) {
		    this.$router.push({name: 'albumDetails', params: {id: albumID, name: window.toHumanFormat(albumName)}});
	    },
        redirectToEditAlbum(albumID, albumName) {
            this.$router.push({name: 'createMusicRelease', params: {id: albumID, name: window.toHumanFormat(albumName), editRelease: true}});
        },
	    redirectToArtistPage(artistId, artistName) {
			console.log('artistId, artistName', artistId, artistName);
		    // const name = artistName.trim().replace(/ /g, '-');
		    this.$router.push({name: 'artist-profile', params: {id: artistId, name: window.toHumanFormat(artistName)}});
	    },
        setOrderBy: function (key, direction) {
            if (key && direction) {
                this.$store.commit('manageUploadsReleases/SET_ORDER_BY', {
                    sort: key, order: direction
                });
            }
        },
    }
}
</script>

<style scoped>
.downtime {
    color: #E94E4E;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    display: block;
}
</style>
